@import "main.css";
.navbar-brand { height: 10vh !important; width: 10vw !important; }
.logo_nav { height: 14vh !important }
.animated-border-button:after {
background-color: #BB6E00;
}
.slider-with-features-carousel-logo {
height: 30vh !important;
}
.websitePageTitle-div {
display : none !important;
}
#navigation-bar-hide {
opacity: 1 !important;
top: 0 !important;
}